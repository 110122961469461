<template>
  <div class="project-container">
    <template v-if="project">
      <sbs-tree />
      <div class="d-flex flex-column flex">
        <portal-target
          v-if="$route.meta.globalToolbar"
          key="projectToolbar"
          name="ant-toolbar"
        >
          <ant-toolbar-container title="ANT VIEWER" />
        </portal-target>
        <div class="project-router-container">
          <transition mode="out-in" name="fade">
            <router-view />
          </transition>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PortalTarget } from 'portal-vue';
import AntToolbarContainer from '@/components/AntToolbarContainer';
import VueMetaMixin from '@/Mixins/VueMetaMixin';

export default {
  name: 'Project',
  components: {
    AntToolbarContainer,
    PortalTarget,
    SbsTree: () =>
      import(
        /* webpackChunkName: "Project" */ '@/components/Project/SBS/SbsTree'
      ),
  },
  mixins: [VueMetaMixin],
  computed: {
    ...mapGetters([
      'authenticatedUser',
      'projects',
      'project',
      'sbsSidebarToggle',
      'selectedLicense',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.project-container {
  display: flex;
  flex: 1 1 auto;
  min-height: 0;

  .project-router-container {
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.3s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
    }

    flex: 1;
    display: flex;
    overflow: hidden;

    div {
      flex: 1;
    }
  }
}
</style>
